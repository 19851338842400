import barba from "@barba/core";
import "core-js/stable";
import "regenerator-runtime/runtime";
import Headroom from "headroom.js";
import Menu from "./js/Menu";
import Animations from "./js/Animations";
import Popup from "./js/Popup";
import lazySizes from "lazysizes";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap/dist/gsap";
import Slider from "./js/Slider";
import "./app.scss";

let actions = {};
let actionChain = new Map();
function registerAction(action) {
  actions[action.name] = {
    mount: action,
  };
}

// barba.hooks.beforeEnter((data) => {});
// barba.hooks.after((data) => {
//   runActions();
// });

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}

gsap.registerPlugin(ScrollTrigger);
registerAction(Animations);

runActions();

function runActions() {
  console.log("runActions");
  action("Slider", Slider);

  Menu(document.querySelectorAll(".mini-nav-toggle"));
  Popup(document.querySelectorAll(".popup-btn"));

  const headroomElement = document.querySelector("#main-header");
  const headroom = new Headroom(headroomElement, {
    offset: 0,
  });
  headroom.init();

  const modelBtn = document.querySelectorAll(".mob-nav-btn");

  if (modelBtn) {
    modelBtn.forEach((btn) =>
      btn.addEventListener("click", (e) => {
        console.log(e.target.closest(".mob-nav"));
        e.target.closest(".mob-nav").classList.add("hidden");
        document.body.classList.remove("overflow-hidden");
      })
    );
  }
}
