import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import eventBus from "./utils/eventBus";
gsap.registerPlugin(ScrollTrigger);

export default function Animations(el) {
  eventBus.on("scrolltrigger:refresh", () => ScrollTrigger.refresh());
}

//Header Block

gsap.from(".header-title", { duration: 1, y: "100%", opacity: 0, delay: 1 });
gsap.from(".header-subTitle", {
  duration: 1,
  y: "100%",
  opacity: 0,
  delay: 1.2,
});
gsap.from(".header-nav1", { duration: 0.3, opacity: 0, delay: 1.5 });
gsap.from(".header-nav2", { duration: 0.3, opacity: 0, delay: 1.6 });
gsap.from(".header-nav3", { duration: 0.3, opacity: 0, delay: 1.7 });
gsap.from(".header-nav4", { duration: 0.3, opacity: 0, delay: 1.8 });
gsap.from(".header-nav5", { duration: 0.3, opacity: 0, delay: 1.9 });
gsap.from(".nav-delay", { duration: 0.5, opacity: 0, delay: 0.7 });

//  text image block

gsap.from(".ti-head", {
  stagger: 1,
  duration: 1,
  y: "100%",
  scrollTrigger: {
    trigger: ".ti-head",
    start: "top 95%",
    end: "top 70%",
    scrub: 2,
    toggleActions: "play pause resume reset",
  },
});

gsap.from(".ti-block1", {
  stagger: 2,
  duration: 2,
  opacity: 0,
  scrollTrigger: {
    trigger: ".ti-block1",
    start: "top 60%",
    end: "top 30%",
    scrub: 2,
    toggleActions: "play pause resume reset",
  },
});

// slider

gsap.from(".project-head", {
  duration: 1,
  y: "100%",
  scrollTrigger: {
    trigger: ".project-head",
    start: "top 80%",
    end: "top 60%",
    scrub: 2,
  },
});

gsap.from(".project-subhead", {
  duration: 1,
  opacity: 0,
  scrollTrigger: {
    trigger: ".project-subhead",
    start: "top 70%",
    end: "top 50%",
    scrub: 2,
  },
});

// centered text

gsap.from(".centered-head", {
  opacity: 0,
  duration: 1,
  scrollTrigger: {
    trigger: ".centered-head",
    start: "top 80%",
    end: "top 60%",
    scrub: 2,
  },
});

gsap.from(".centered-text", {
  opacity: 0,
  duration: 1,
  scrollTrigger: {
    trigger: ".centered-text",
    start: "top 80%",
    end: "top 60%",
    scrub: 2,
  },
});

//text image

gsap.from(".side-thing1", {
  opacity: 0,
  duration: 2,
  y: "100%",
  scrollTrigger: {
    trigger: ".side-thing1",
    start: "top 150%",
    end: "top 90%",
    scrub: 2,
  },
});

gsap.from(".side-thing2", {
  opacity: 0,
  duration: 2,
  y: "100%",
  scrollTrigger: {
    trigger: ".side-thing2",
    start: "top 150%",
    end: "top 90%",
    scrub: 2,
  },
});

gsap.from(".side-thing3", {
  opacity: 0,
  y: "100%",
  duration: 2,
  scrollTrigger: {
    trigger: ".side-thing3",
    start: "top 150%",
    end: "top 90%",
    scrub: 2,
  },
});
